import '@stylus/main.styl'

import { App } from '@templates'
import Axios from 'axios'
import router from '@/router'
import store from '@/store'
import Vue from 'vue'
// https://fontawesome.com/docs/web/use-with/vue/dig-deeper
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { VueSvgIcon } from '@yzfe/vue-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'
import VueMeta from 'vue-meta'
import ScrollMagic from 'vue-scrollmagic';
import MarqueeText from 'vue-marquee-text-component'



Vue.config.productionTip = false

Vue.use(ScrollMagic, {
	addIndicators: false,
});

Vue.component('MarqueeText', MarqueeText)
Vue.component('SvgIcon', VueSvgIcon)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.use(VueMeta)

const base = document.querySelector('base')
Vue.prototype.$axios = Axios.create({
	baseURL: `${base.href}api`
})

Vue.component('SvgIcon', VueSvgIcon)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
